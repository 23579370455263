import { Component } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { getFieldValue } from '@ngx-formly/core/lib/utils';
import { FormlyFieldConfigCache } from '@ngx-formly/core/lib/components/formly.field.config';

@Component({
  selector: 'formly-field-readonly',
  template: `
    <div *ngIf="fieldModel; else placeholder" [ngSwitch]="type">
      <p *ngSwitchCase="'date'">{{ fieldModel | date: 'mediumDate' }}</p>
      <p *ngSwitchCase="'name'">{{ fieldModel | name }}</p>
      <p *ngSwitchCase="'fullName'">{{ fieldModel | fullName }}</p>
      <p *ngSwitchCase="'address'">{{ fieldModel | address }}</p>
      <p *ngSwitchCase="'fullAddress'">{{ fieldModel | fullAddress }}</p>
      <p *ngSwitchCase="'currency'">{{ fieldModel | currency: 'EUR' }}</p>
      <p *ngSwitchDefault>{{ fieldModel }}</p>
    </div>
    <ng-template #placeholder>
      <p>{{ to.placeholder }}</p>
    </ng-template>
  `
})
export class FormlyFieldReadonly extends FieldType {
  get type() {
    return this.to.type || 'text';
  }

  get fieldModel() {
    return this.getFieldValue(this.field);
  }

  private getValueProp(item, to): string {
    if (typeof to.valueProp === 'function') {
      return to.valueProp(item);
    }

    // if (this.shouldUseLegacyOption(item, to)) {
    //   return item.key;
    // }

    return item[to.valueProp || 'value'];
  }

  private getKeyPath(field: FormlyFieldConfigCache): string[] {
    if (!field.key) {
      return [];
    }

    /* We store the keyPath in the field for performance reasons. This function will be called frequently. */
    if (!field._keyPath || field._keyPath.key !== field.key) {
      const key =
        field.key.indexOf('[') === -1
          ? field.key
          : field.key.replace(/\[(\w+)\]/g, '.$1');

      field._keyPath = {
        key: field.key,
        path: key.indexOf('.') !== -1 ? key.split('.') : [key]
      };
    }

    return field._keyPath.path.slice(0);
  }

  private getFieldValue(field: FormlyFieldConfig): any {
    let model = field.parent.model;
    for (const path of this.getKeyPath(field)) {
      if (!model) {
        return model;
      }
      model = model[path];
    }

    return model;
  }
}
