import { Action, Selector, State, StateContext } from '@ngxs/store';

import { Deal } from '../../../models';
import { LoadDeal } from './current-deal.actions';
import { DealService } from '../../../resources';
import { map, tap } from 'rxjs/operators';

export interface CurrentDealStateModel {
  deal?: Deal;
}

@State<CurrentDealStateModel>({
  name: 'currentDeal',
  defaults: {
    deal: null
  }
})
export class CurrentDealState {
  constructor(private dealService: DealService) {}
  @Selector()
  public static deal(state: CurrentDealStateModel): Deal {
    return state.deal;
  }

  @Action(LoadDeal)
  public loadLead(ctx: StateContext<CurrentDealStateModel>, { id }: LoadDeal) {
    return this.dealService.get({ id: id }).pipe(
      map(res => res['deal']),
      tap((deal: Deal) => {
        ctx.patchState({ deal: deal });
      })
    );
  }
}
