import { Injectable } from '@angular/core';
import { Pipe, PipeTransform } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
@Pipe({ name: 'name' })
export class NamePipe implements PipeTransform {
  transform(profile: any) {
    return profile.firstName + ' ' + profile.lastName;
  }
}

@Injectable({
  providedIn: 'root'
})
@Pipe({ name: 'fullName' })
export class FullNamePipe implements PipeTransform {
  transform(profile: any) {
    const arr = [];

    if (profile.title) {
      arr.push(profile.title);
    }

    if (profile.gender) {
      arr.push(profile.gender);
    }

    if (profile.firstName) {
      arr.push(profile.firstName);
    }

    if (profile.lastName) {
      arr.push(profile.lastName);
    }
    return arr.join(' ');
  }
}
