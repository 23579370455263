import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgxsModule } from '@ngxs/store';

import { CurrentLeadState } from './current-lead/current-lead.state';
import { LeadsState } from './leads.state';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([LeadsState, CurrentLeadState])
  ],
  exports: [NgxsModule]
})
export class LeadsStoreModule {}
