import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { ToggleAside } from '../../store/layout.state';

@Component({
  selector: 'clemos-exoplanets-aside-toggler',
  templateUrl: './aside-toggler.component.html',
  styleUrls: ['./aside-toggler.component.scss']
})
export class AsideTogglerComponent {
  constructor(private store: Store) {}

  toggleAside() {
    this.store.dispatch(new ToggleAside());
  }
}
