import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'formly-field-input',
  template: `
    <international-phone-number
      [(ngModel)]="fieldModel"
      placeholder="Enter phone number"
      [maxlength]="20"
      [defaultCountry]="'at'"
      [required]="true"
      #phoneNumber="ngModel"
      name="phone_number"
      [allowedCountries]="allowedCountries"
    ></international-phone-number>
  `,
  host: {
    '[class.d-inline-flex]': 'to.addonLeft || to.addonRight',
    '[class.custom-file]': 'to.addonLeft || to.addonRight'
  }
})
export class FormlyFieldPhone extends FieldType {
  allowedCountries = [
    'AT',
    'DE',
    'BE',
    'BG',
    'CY',
    'CZ',
    'DK',
    'EE',
    'FI',
    'FR',
    'GR',
    'HR',
    'HU',
    'IE',
    'IT',
    'LV',
    'LT',
    'LU',
    'MT',
    'NL',
    'PL',
    'PT',
    'RO',
    'SK',
    'SI',
    'ES',
    'SE',
    'GB',
    'RD'
  ];

  get fieldModel() {
    return this.model[this.field.key];
  }
}
