import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TypeOfEmploymentOptionsService {
  options = [
    {
      value: 'full_time',
      label: 'Angestellt (Vollzeit)'
    },
    {
      value: 'part_time',
      label: 'Angestellt (Teilzeit)'
    },
    {
      value: 'self_employed',
      label: 'Selbständig'
    },
    {
      value: 'freelancer',
      label: 'Freiberufler'
    },
    {
      value: 'public_servant',
      label: 'Beamter'
    },
    {
      value: 'uno_employee',
      label: 'UNO Mitarbeiter'
    },
    {
      value: 'retireement',
      label: 'in Pension'
    },
    {
      value: 'others',
      label: 'Sonstiges'
    }
  ];

  get(): Observable<any[]> {
    return of(this.options);
  }
}
