import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Coach } from '../../core/models/coach.model';
import { CoachService } from '../../core/resources/coach.service';

@Injectable({
  providedIn: 'root'
})
export class CoachOptionsService {
  constructor(private coachService: CoachService) {}

  get(): Observable<Coach[]> {
    return this.coachService.query().pipe(map(res => res['coaches']));
  }
}
