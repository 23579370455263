import {
  Directive,
  ElementRef,
  HostListener,
  AfterViewInit,
  Renderer2
} from '@angular/core';

@Directive({
  selector: '[doFloat]'
})
export class DoFloatDirective implements AfterViewInit {
  constructor(private el: ElementRef, private renderer: Renderer2) {
    const parentHostEl = el.nativeElement.parentNode;
    renderer.addClass(parentHostEl, 'do-float');
  }

  ngAfterViewInit() {}
}
