import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { InstitutionBranch } from '../../../core/models/institution/branch.model';
import { InstitutionBranchService } from '../../../core/resources/institution/branch.service';

@Injectable({
  providedIn: 'root'
})
export class InstitutionBranchOptionsService {
  constructor(private institutionBranchService: InstitutionBranchService) {}

  get(): Observable<InstitutionBranch[]> {
    return this.institutionBranchService
      .query()
      .pipe(map(res => res['institution/branches']));
  }
}
