import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgxsModule } from '@ngxs/store';

import { DealsState } from './deals.state';
import { CurrentDealState } from './current-deal/current-deal.state';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([DealsState, CurrentDealState])
  ],
  exports: [NgxsModule]
})
export class DealsStoreModule {}
