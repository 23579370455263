import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[aside]'
})
export class AsideDirective {
  @HostBinding('class.aside')
  @HostBinding('class.aside-expand-md')
  test() {}
}
