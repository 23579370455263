import { Component, HostBinding, OnInit, OnDestroy } from '@angular/core';
import { untilDestroy } from '@clemos/utils';
import { Select, Store } from '@ngxs/store';
import { MenuItem } from '@clemos/core';
import { MenuState } from '../../store/menu.state';
import { tap } from 'rxjs/operators';
import { shiftInitState } from '@angular/core/src/view';
import { Observable } from 'rxjs';

@Component({
  selector: 'clemos-exoplanets-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  @Select(MenuState.sidebarMenu) menuItems$: Observable<MenuItem[]>;

  constructor() {}

  @HostBinding('class.sidebar')
  @HostBinding('class.sidebar-expand-lg')
  @HostBinding('class.sidebar-iconic')
  @HostBinding('attr.debug-id')
  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
