import {
  Action,
  Selector,
  State,
  StateContext,
  Actions,
  ofActionCompleted,
  Store
} from '@ngxs/store';
import { patch } from '@ngxs/store/operators';

import { tap } from 'rxjs/operators';

import { Document } from '../../models';
import { DocumentService } from '../../resources';

import { LoadDocuments, ChangePageDocuments } from './documents.actions';
import { CurrentLeadState } from '../leads';
import { CurrentDealState } from '../deals';

export interface DocumentsStateModel {
  documents: Document[];
  meta: {
    currentPage: number;
    totalCount: number;
    totalPages: number;
  };
}

@State<DocumentsStateModel>({
  name: 'documents',
  defaults: {
    documents: [],
    meta: {
      currentPage: 1,
      totalCount: 0,
      totalPages: 0
    }
  }
})
export class DocumentsState {
  constructor(private documentService: DocumentService, private store: Store) {}

  @Selector()
  public static documents(state: DocumentsStateModel): Document[] {
    return state.documents;
  }

  @Selector()
  public static meta(state: DocumentsStateModel): any {
    return state.meta;
  }

  @Action(LoadDocuments)
  public loadDocuments(
    ctx: StateContext<DocumentsStateModel>,
    { contextType }: LoadDocuments
  ) {
    const meta = ctx.getState().meta;
    let params: any = { page: meta.currentPage };

    switch (contextType) {
      case 'lead': {
        params = {
          ...params,
          lead_id: this.store.selectSnapshot(CurrentLeadState.lead).id
        };
        break;
      }
      case 'deal': {
        params = {
          ...params,
          deal_id: this.store.selectSnapshot(CurrentDealState.deal).id
        };
        break;
      }
      default: {
        break;
      }
    }
    return this.documentService
      .query(
        {},
        {
          params: params
        }
      )
      .pipe(
        tap(res => {
          ctx.setState(
            patch({
              documents: res['documents'],
              meta: res['meta']
            })
          );
        })
      );
  }

  @Action(ChangePageDocuments)
  public changePageDocuments(
    ctx: StateContext<DocumentsStateModel>,
    { page }: ChangePageDocuments
  ) {
    ctx.setState(
      patch({
        meta: patch({
          currentPage: page
        })
      })
    );
    // this.store.dispatch(new LoadDocuments()).subscribe();
  }
}
