import { NgModule, Injector } from '@angular/core';

// libs
import { environment } from '@clemos/core';

// xplat
import { ExoplanetsModule } from '@clemos/web';

// app
import { CoreModule } from './core/core.module';
import { SharedModule } from './features/shared/shared.module';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';

@NgModule({
  imports: [CoreModule, SharedModule, AppRoutingModule, ExoplanetsModule],
  declarations: [AppComponent],
  bootstrap: [AppComponent]
})
export class AppModule {
  static injector: Injector;
  constructor(injector: Injector) {
    AppModule.injector = injector;
  }
}
