export enum EmploymentType {
  fullTime,
  partTime,
  selfEmployed,
  retirement,
  publicServant,
  unoEmployee,
  freelancer,
  others
}
