import { DateOrderPipe } from './date-order.pipe';
import { NamePipe, FullNamePipe } from './name.pipe';
import { AddressPipe, FullAddressPipe } from './address.pipe';

export const UI_PIPES = [
  DateOrderPipe,
  NamePipe,
  FullNamePipe,
  AddressPipe,
  FullAddressPipe
];

export * from './date-order.pipe';
export * from './name.pipe';
export * from './address.pipe';
