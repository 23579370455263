import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-date-picker',
  template: `
    <ngb-datepicker #d></ngb-datepicker>
  `,
  host: {
    '[class.d-inline-flex]': 'to.addonLeft || to.addonRight',
    '[class.custom-file]': 'to.addonLeft || to.addonRight'
  }
})
export class FormlyFieldDatePicker extends FieldType {}
