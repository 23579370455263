// Angular core modules
import { NgModule, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeDE from '@angular/common/locales/de';
import { HttpClient } from '@angular/common/http';

// ngx-translate modules
import {
  TranslateModule,
  TranslateLoader,
  TranslateService
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// angular-moment
import 'moment/locale/de';

registerLocaleData(localeDE);

// AoT requires an exported function for factories
export function TranslateHttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, 'api/locales.json?lang=', '');
}

@NgModule({
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateHttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [TranslateModule],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'de'
    }
  ]
})
export class I18nConfigModule {
  constructor(translate: TranslateService) {
    translate.use('de');
  }
}
