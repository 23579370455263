import { Injectable } from '@angular/core';
import { Pipe, PipeTransform } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
@Pipe({ name: 'address' })
export class AddressPipe implements PipeTransform {
  transform(addressable: any) {
    const arr = [];

    if (addressable.streetAddress) {
      arr.push(addressable.streetAddress);
    }

    if (addressable.postalCode && addressable.place) {
      arr.push(addressable.postalCode + ' ' + addressable.place);
    }
    return arr.join(', ');
  }
}

@Injectable({
  providedIn: 'root'
})
@Pipe({ name: 'fullAddress' })
export class FullAddressPipe implements PipeTransform {
  transform(addressable: any) {
    const arr = [];

    if (addressable.streetAddress) {
      arr.push(addressable.streetAddress);
    }

    if (addressable.postalCode && addressable.place) {
      arr.push(addressable.postalCode + ' ' + addressable.place);
    }

    if (addressable.stateCode) {
      arr.push(addressable.stateCode);
    }
    return arr.join(', ');
  }
}
