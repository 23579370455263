import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BooleanOptionsService {
  options = [{ value: true, label: 'Ja' }, { value: false, label: 'Nein' }];

  get(): Observable<any[]> {
    return of(this.options);
  }
}
