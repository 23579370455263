import { snakeCase, camelCase } from 'change-case';

export class Inflector {
  static snakeCaseKeys(obj) {
    const newObj = {};

    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        const val = obj[prop];
        if (typeof val === 'object') {
          newObj[snakeCase(prop)] = this.snakeCaseKeys(val);
        } else {
          newObj[snakeCase(prop)] = obj[prop];
        }
      }
    }

    return newObj;
  }

  static camelCaseKeys(obj) {
    const newObj = {};

    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        const val = obj[prop];
        if (typeof val === 'object') {
          newObj[camelCase(prop)] = this.camelCaseKeys(val);
        } else {
          newObj[camelCase(prop)] = obj[prop];
        }
      }
    }

    return newObj;
  }
}
