import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgxsModule } from '@ngxs/store';

import { DocumentsState } from './documents.state';

@NgModule({
  imports: [CommonModule, NgxsModule.forFeature([DocumentsState])],
  exports: [NgxsModule]
})
export class DocumentsStoreModule {}
