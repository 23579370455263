import { Deal } from './deal.model';
import { Product } from './product.model';
import { Applicant } from './applicant.model';
import { Assignment } from './assignment.model';

export interface Lead {
  id?: string;
  number?: string;
  productCode?: string;
  product?: Product;
  institutionIds?: string[];
  deals?: Deal[];
  amount?: string;
  status?: string;
  property?: any;
  loan?: any;
  assignment?: Assignment;
  applicant?: Applicant;
  applicants?: Applicant[];
  household?: any;
  createdAt?: Date;
  updatedAt?: Date;
}

export const defaultPropertyCost = {
  purchaseCost: 0,
  constructionCost: 0,
  debtRestructuringCost: 0,
  landTax: 0,
  realPropertyTransferTax: 0,
  withBroker: true,
  withPunitiveDamages: true,
  notaryCost: 0,
  brokerCommission: 0,
  punitiveDamages: 0,
  otherPurchaseCost: 0,
  additionalCost: 0,
  furnitureCost: 0,
  renovationCost: 0,
  upgradingCost: 0,
  landscapingCost: 0,
  enclosureCost: 0,
  totalImprovementCost: 0,
  othersCost: 500,
  totalInvestmentCost: 0,
  bankFee: 0,
  landRegistryFee: 0
};
