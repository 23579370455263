import {
  Action,
  Selector,
  State,
  StateContext,
  Actions,
  ofActionCompleted,
  Store
} from '@ngxs/store';
import { patch } from '@ngxs/store/operators';

import { tap, map } from 'rxjs/operators';

import { Lead } from '../../models';
import { LeadService } from '../../resources';

import { LoadLeads, ChangePageLeads } from './leads.actions';
import { CurrentLeadState } from './current-lead/current-lead.state';

export interface LeadsStateModel {
  leads: Lead[];
  meta: {
    currentPage: number;
    totalCount: number;
    totalPages: number;
  };
}

@State<LeadsStateModel>({
  name: 'leads',
  defaults: {
    leads: [],
    meta: {
      currentPage: 1,
      totalCount: 0,
      totalPages: 0
    }
  },
  children: [CurrentLeadState]
})
export class LeadsState {
  constructor(private leadService: LeadService, private store: Store) {}

  @Selector()
  public static leads(state: LeadsStateModel): Lead[] {
    return state.leads;
  }

  @Selector()
  public static meta(state: LeadsStateModel): any {
    return state.meta;
  }

  @Action(LoadLeads)
  public loadLeads(ctx: StateContext<LeadsStateModel>) {
    const meta = ctx.getState().meta;
    const params = { page: meta.currentPage };
    return this.leadService
      .query(
        {},
        {
          params: params
        }
      )
      .pipe(
        tap(res => {
          ctx.setState(
            patch({
              leads: res['leads'],
              meta: res['meta']
            })
          );
        })
      );
  }

  @Action(ChangePageLeads)
  public changePageLeads(
    ctx: StateContext<LeadsStateModel>,
    { page }: ChangePageLeads
  ) {
    ctx.setState(
      patch({
        meta: patch({
          currentPage: page
        })
      })
    );
    this.store.dispatch(new LoadLeads()).subscribe();
  }
}
