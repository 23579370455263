import { Component, OnInit, HostBinding, OnDestroy } from '@angular/core';
import { untilDestroy } from '@clemos/utils';
import { Select, Actions, ofActionDispatched, Store } from '@ngxs/store';
import { AngularTokenService } from 'angular-token';
import { Observable, Subscription } from 'rxjs';

import { faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { Coach, MenuItem, InstitutionUser } from '@clemos/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { OpenSidebar, CloseSidebar } from '../../store/layout.state';
import { MenuState } from '../../store/menu.state';
import { AuthState } from '@clemos/core';

@Component({
  selector: 'clemos-exoplanets-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit, OnDestroy {
  @Select(MenuState.topbarMenu) topbarMenu$;
  @Select(AuthState.currentUser) currentUser$: Observable<
    Coach | InstitutionUser
  >;

  menuItems: MenuItem[];

  faPowerOff = faPowerOff;
  output: any;

  constructor(
    private store: Store,
    public tokenService: AngularTokenService,
    public dropdownConfig: NgbDropdownConfig
  ) {
    this.dropdownConfig.placement = 'bottom-right';
  }

  @HostBinding('class.topbar')
  closeSidebar() {
    this.store.dispatch(new CloseSidebar());
  }

  openSidebar() {
    this.store.dispatch(new OpenSidebar());
  }

  signOut() {
    this.tokenService.signOut().subscribe(
      res => {
        setTimeout(function() {
          this.output = res;
        }, 100);
      },
      error => (this.output = error)
    );
  }

  ngOnInit(): void {
    this.topbarMenu$.pipe(untilDestroy(this)).subscribe(menuItems => {
      this.menuItems = menuItems;
    });
  }

  ngOnDestroy(): void {}
}
