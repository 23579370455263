import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AngularTokenService } from 'angular-token';
import { InsideComponent } from '@clemos/web';
import { AuthResolver } from '@clemos/core';

const routes: Routes = [
  {
    path: 'inside',
    component: InsideComponent,
    canActivate: [AngularTokenService],
    resolve: {
      currentUser: AuthResolver
    },
    children: [
      // {
      //   path: 'account',
      //   loadChildren: './features/account#AccountModule',
      //   data: {title: 'Konto'}
      // },
      // {
      //   path: 'customers',
      //   loadChildren: './features/customers/customers.module#CustomersModule'
      // },
      // {
      //   path: 'dashboard',
      //   loadChildren: './features/dashboard/dashboard.module#DashboardModule'
      // },
      {
        path: 'deals',
        loadChildren: './features/deals#DealsModule'
      },
      // {
      //   path: 'invoices',
      //   loadChildren: './features/invoices/invoices.module#InvoicesModule'
      // },
      // {
      //   path: 'reports',
      //   loadChildren: './features/reports/reports.module#ReportsModule'
      // },
      // {
      //   path: 'settings',
      //   loadChildren: './features/settings/settings.module#SettingsModule'
      // },
      {
        path: '',
        redirectTo: '/inside/deals',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: 'auth',
    loadChildren: './features/auth#AuthModule'
  },
  {
    path: '',
    redirectTo: '/inside/deals',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
