import { State, Selector, Action, StateContext } from '@ngxs/store';
import { MenuItem } from '@clemos/core/models';

export class SetSidebarMenu {
  static readonly type = '[Menu] Set sidebar menu';
  constructor(public payload: MenuItem[]) {}
}

export class SetTopbarMenu {
  static readonly type = '[Menu] Set topbar menu';
  constructor(public payload: MenuItem[]) {}
}

export interface MenuStateModel {
  sidebar: MenuItem[];
  topbar: MenuItem[];
}

/** @dynamic */
@State<MenuStateModel>({
  name: 'menu',
  defaults: {
    sidebar: [],
    topbar: []
  }
})
export class MenuState {
  constructor() {}

  @Selector()
  public static sidebarMenu(state: MenuStateModel) {
    return state.sidebar;
  }

  @Selector()
  public static topbarMenu(state: MenuStateModel) {
    return state.topbar;
  }

  @Action(SetSidebarMenu)
  setSidebarMenu(
    ctx: StateContext<MenuStateModel>,
    { payload }: SetSidebarMenu
  ) {
    ctx.patchState({
      sidebar: payload
    });
  }

  @Action(SetTopbarMenu)
  setTopbarMenu(ctx: StateContext<MenuStateModel>, { payload }: SetTopbarMenu) {
    ctx.patchState({
      topbar: payload
    });
  }
}
