import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResourceService } from 'ng-restly';

@Injectable({
  providedIn: 'root'
})
export class LeadService extends ResourceService {
  constructor(protected http: HttpClient) {
    super(http);
    this.url = 'api/leads/:id';
  }

  assign(id: string, coachId?: string) {
    this.url = 'api/leads/:id/assign';

    const data = {
      lead: {
        assignment: {
          assigneeId: coachId
        }
      }
    };
    const ret = super.save(data, { id: id });

    this.url = 'api/leads/:id';

    return ret;
  }
}
