// Angular core modules
import { NgModule } from '@angular/core';

// app
import { environment } from '../environments/environment';

// Ngxs modules
import { NgxsModule } from '@ngxs/store';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import {
  NgxsRouterPluginModule,
  RouterStateSerializer
} from '@ngxs/router-plugin';
import { AppState } from '../store/app.state';
import { AuthState } from '../store/auth.state';
import { CustomRouterStateSerializer } from '../store/custom-router-state.serializer';

@NgModule({
  imports: [
    NgxsModule.forRoot([AppState, AuthState], {
      developmentMode: !environment.production
    }),
    NgxsStoragePluginModule.forRoot({
      key: ['leadWizard']
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production, // Set to true for prod mode
      maxAge: 10
    }),
    NgxsFormPluginModule.forRoot(),
    NgxsRouterPluginModule.forRoot()
  ],
  providers: [
    {
      provide: RouterStateSerializer,
      useClass: CustomRouterStateSerializer
    }
  ]
})
export class NgxsConfigModule {}
