import { State, Selector, Action, StateContext } from '@ngxs/store';

import { Lead } from '../../../models';
import { LoadLead, AssignLead } from './current-lead.actions';
import { LeadService } from '../../../resources';
import { map, tap } from 'rxjs/operators';

export interface CurrentLeadStateModel {
  lead?: Lead;
}

@State<CurrentLeadStateModel>({
  name: 'currentLead',
  defaults: {
    lead: null
  }
})
export class CurrentLeadState {
  constructor(private leadService: LeadService) {}

  @Selector()
  public static lead(state: CurrentLeadStateModel): Lead {
    return state.lead;
  }

  @Selector()
  public static assignment(state: CurrentLeadStateModel): Lead {
    return state.lead.assignment;
  }

  @Selector()
  public static coach(state: CurrentLeadStateModel): Lead {
    return state.lead.assignment.assignee;
  }

  @Action(LoadLead)
  public loadLead(ctx: StateContext<CurrentLeadStateModel>, { id }: LoadLead) {
    return this.leadService.get({ id: id }).pipe(
      map(res => res['lead']),
      tap(lead => {
        ctx.patchState({ lead: lead });
      })
    );
  }

  @Action(AssignLead)
  public assignLead(
    ctx: StateContext<CurrentLeadStateModel>,
    { coachId }: AssignLead
  ) {
    const lead = ctx.getState().lead;
    return this.leadService.assign(lead.id, coachId).pipe(
      map(res => res['lead']),
      tap(lead => {
        ctx.patchState({ lead: lead });
      })
    );
  }
}
