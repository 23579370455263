import { Customer, Coach, InstitutionUser } from '@clemos/core';
import { State, Selector, Action, StateContext } from '@ngxs/store';

export class SetCurrentUser {
  static readonly type = '[Auth] Set current user';
  constructor(public user: Customer | Coach | InstitutionUser) {}
}

export interface AuthStateModel {
  currentUser?: Customer | Coach | InstitutionUser;
}

/** @dynamic */
@State<AuthStateModel>({
  name: 'auth',
  defaults: {}
})
export class AuthState {
  @Selector()
  static currentUser(state: AuthStateModel) {
    return state.currentUser;
  }

  @Selector()
  static currentUserProfile(state: AuthStateModel) {
    return state.currentUser.profile;
  }

  @Action(SetCurrentUser)
  SetCurrentUser(ctx: StateContext<AuthStateModel>, { user }: SetCurrentUser) {
    ctx.patchState({
      currentUser: user
    });
  }
}
