import { State, Selector, Action, StateContext } from '@ngxs/store';

export class OpenSidebar {
  static readonly type = '[Layout] Open Sidebar';
}
export class CloseSidebar {
  public static type = '[Layout] Close Sidebar';
}
export class ToggleAside {
  public static readonly type = '[Layout] Toggle Aside';
}
export class CloseAside {
  public static readonly type = '[Layout] Close Aside';
}
export class OpenQuickview {
  static readonly type = '[Layout] Open Quickview';
}
export class CloseQuickview {
  static readonly type = '[Layout] Close Quickview';
}

export interface LayoutStateModel {
  showSidebar: boolean;
  showAside: boolean;
  showQuickview: boolean;
}

/** @dynamic */
@State<LayoutStateModel>({
  name: 'layout',
  defaults: {
    showSidebar: false,
    showAside: false,
    showQuickview: false
  }
})
export class LayoutState {
  constructor() {}

  @Selector()
  public static getState(state: LayoutStateModel) {
    return state;
  }

  @Selector()
  public static showAside(state: LayoutStateModel) {
    return state.showAside;
  }

  @Selector()
  public static showSidebar(state: LayoutStateModel) {
    return state.showSidebar;
  }

  @Selector()
  public static showQuickview(state: LayoutStateModel) {
    return state.showQuickview;
  }

  @Action(OpenSidebar)
  openSidebar(ctx: StateContext<LayoutStateModel>) {
    ctx.patchState({
      showSidebar: true
    });
  }

  @Action(CloseSidebar)
  closeSidebar(ctx: StateContext<LayoutStateModel>) {
    ctx.patchState({
      showSidebar: false
    });
  }

  @Action(ToggleAside)
  toggleAside(ctx: StateContext<LayoutStateModel>) {
    const state = ctx.getState();
    ctx.patchState({
      showAside: !state.showAside
    });
  }
  @Action(CloseAside)
  closeAside(ctx: StateContext<LayoutStateModel>) {
    ctx.patchState({
      showAside: false
    });
  }

  @Action(OpenQuickview)
  openQuickview(ctx: StateContext<LayoutStateModel>) {
    ctx.patchState({
      showQuickview: true
    });
  }

  @Action(CloseQuickview)
  closeQuickview(ctx: StateContext<LayoutStateModel>) {
    ctx.patchState({
      showQuickview: false
    });
  }
}
