import { FilterComponent } from './filter/filter.component';
import { AsideTogglerComponent } from './aside-toggler/aside-toggler.component';
import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TopbarComponent } from './topbar/topbar.component';
import { BackdropComponent } from './backdrop/backdrop.component';

export const COMPONENTS = [
  AsideTogglerComponent,
  FilterComponent,
  FooterComponent,
  SidebarComponent,
  TopbarComponent,
  BackdropComponent
];

export * from './filter/filter.component';
export * from './aside-toggler/aside-toggler.component';
export * from './footer/footer.component';
export * from './sidebar/sidebar.component';
export * from './topbar/topbar.component';
export * from './backdrop/backdrop.component';
