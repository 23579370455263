import { NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient } from '@angular/common/http';

// libs
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { throwIfAlreadyLoaded } from '@clemos/utils';
import {
  CoreModule,
  PlatformLanguageToken,
  PlatformWindowToken
} from '@clemos/core';
import { FormConfigModule } from './config/form.config';

// bring in custom web services here...

// factories
export function winFactory() {
  return window;
}

export function platformLangFactory() {
  const browserLang = window.navigator.language || 'en'; // fallback English
  // browser language has 2 codes, ex: 'en-US'
  return browserLang.split('-')[0];
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CoreModule.forRoot([
      {
        provide: PlatformLanguageToken,
        useFactory: platformLangFactory
      },
      {
        provide: PlatformWindowToken,
        useFactory: winFactory
      }
    ]),
    FormConfigModule
  ]
})
export class ClemosCoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: ClemosCoreModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'ClemosCoreModule');
  }
}
