import { Action, Selector, State, StateContext } from '@ngxs/store';

import { tap, map } from 'rxjs/operators';

import { Deal } from '../../models';
import { DealService } from '../../resources';

import { LoadDeals } from './deals.actions';
import { CurrentDealState } from './current-deal/current-deal.state';

export interface DealsStateModel {
  deals?: Deal[];
}

@State<DealsStateModel>({
  name: 'deals',
  defaults: {
    deals: []
  },
  children: [CurrentDealState]
})
export class DealsState {
  constructor(private dealService: DealService) {}

  @Selector()
  public static deals(state: DealsStateModel): Deal[] {
    return state.deals;
  }

  @Action(LoadDeals)
  public load({ patchState }: StateContext<DealsStateModel>) {
    return this.dealService.query().pipe(
      map(res => res['deals']),
      tap((deals: Deal[]) => patchState({ deals: deals }))
    );
  }
}
