import { NgModule } from '@angular/core';

// xplat
import { UIModule, ExoplanetsModule } from '@clemos/web';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { MomentModule } from 'ngx-moment';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormlyModule } from '@ngx-formly/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxJsonViewerModule } from 'ngx-json-viewer';

const MODULES = [
  UIModule,
  ExoplanetsModule,
  PerfectScrollbarModule,
  MomentModule,
  NgbModule,
  NgxDatatableModule,
  FormsModule,
  ReactiveFormsModule,
  FormlyModule,
  NgxJsonViewerModule
];

@NgModule({
  imports: [...MODULES],
  exports: [...MODULES]
})
export class SharedModule {}
