import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-radio-buttons',
  template: `
    <div [formGroup]="form" class="btn-group btn-group-toggle" ngbRadioGroup>
      <label
        ngbButtonLabel
        class="btn-primary btn"
        *ngFor="
          let option of (to.options | formlySelectOptions: field | async);
          let i = index
        "
      >
        <input ngbButton type="radio" [value]="option.value" />
        {{ option.label }}
      </label>
    </div>
  `
})
export class FormlyFieldRadioButtons extends FieldType {}
