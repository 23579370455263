import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { InstitutionUser } from '../../../core/models/institution/user.model';
import { InstitutionUserService } from '../../../core/resources/institution/user.service';

@Injectable({
  providedIn: 'root'
})
export class InstitutionUserOptionsService {
  constructor(private institutionUserService: InstitutionUserService) {}

  get(): Observable<InstitutionUser[]> {
    return this.institutionUserService
      .query()
      .pipe(map(res => res['institution/users']));
  }
}
