import { Lead, defaultPropertyCost } from '@clemos/core';

export class LeadCalculator {
  _property: any;
  _oldProperty: any;
  _loan: any;
  _applicants: any;
  _household: any;

  formControl = {
    showConstructionCost: false,
    showPurchaseCost: false,
    showOtherPurchaseCost: false,
    showWithBroker: false,
    showWithPunitiveDamages: false,
    showBrokerCommission: false,
    showDebtRestructuringCost: false,
    showFurnitureCost: false,
    showRenovationCost: false,
    showUpgradingCost: false,
    showLandscapingCost: false,
    showEnclosureCost: false
  };

  constructor(lead: Lead, oldLead: Lead) {
    this._property = lead.property;
    this._oldProperty = oldLead.property;
    this._loan = lead.loan;
    this._applicants = lead.applicants;
    this._household = lead.household;

    this.calcFormControl();
    this.resetPropertyCost();
    this.calcPropertyCost();
    this.calcLoan();
    this.calcHousehold();
  }

  get lead() {
    return {
      property: this.property,
      loan: this.loan,
      household: this.household,
      applicants: this.applicants
    };
  }

  get property() {
    return this._property;
  }

  get loan() {
    return this._loan;
  }

  get household() {
    return this._household;
  }

  get applicants() {
    return this._applicants;
  }

  getFormControl() {
    return this.formControl;
  }

  calcPropertyCost() {
    // if (this._property.purchaseCost > 0) {
    this._property.landTax = this._property.purchaseCost * 0.011;
    this._property.realEstateTransferTax =
      (this._property.purchaseCost + this._property.debtRestructuringCost) *
      0.035;
    this._property.notaryCost = this._property.purchaseCost * 0.02;

    if (this._property.withBroker === true) {
      this._property.showBrokerCommission = true;
      if (this._property.purchaseCost <= 36337) {
        this._property.brokerCommission = this._property.purchaseCost * 0.048;
      } else if (
        this._property.purchaseCost > 36337 &&
        this._property.purchaseCost <= 48449
      ) {
        this._property.brokerCommission = 1453.46;
      } else {
        this._property.brokerCommission = this._property.purchaseCost * 0.036;
      }
    } else {
      this._property.showBrokerCommission = false;
      this._property.brokerCommission = 0;
    }

    this._property.additionalCost =
      this._property.landTax +
      this._property.realEstateTransferTax +
      this._property.notaryCost +
      this._property.brokerCommission +
      this._property.otherPurchaseCost;

    this._property.totalPurchaseCost =
      this._property.purchaseCost +
      this._property.constructionCost +
      this._property.additionalCost;
    // }

    if (this._property.purpose === 'debt_restructuring') {
      this._property.othersCost = 0;
      this._loan.loanAmount = 0;
    }

    if (this._property.withPunitiveDamages) {
      this._property.punitiveDamages =
        this._property.debtRestructuringCost * 0.01;
    } else {
      this._property.punitiveDamages = 0;
    }

    this._property.totalImprovementCost =
      this._property.furnitureCost +
      this._property.renovationCost +
      this._property.upgradingCost +
      this._property.landscapingCost +
      this._property.enclosureCost;

    this._property.totalInvestmentCost =
      this._property.totalPurchaseCost +
      this._property.totalImprovementCost +
      this._property.debtRestructuringCost +
      this._property.punitiveDamages;

    if (this._property.totalInvestmentCost > 0) {
      this._property.totalInvestmentCost += this._property.othersCost;
    }

    if (this._property.totalInvestmentCost > 0 && this._loan.downPayment >= 0) {
      this._loan.bankFee =
        (this._property.totalInvestmentCost - this._loan.downPayment) * 0.02;
      this._loan.landRegistryFee =
        (this._property.totalInvestmentCost - this._loan.downPayment) *
        1.2 *
        0.012;
      this._loan.loanAmount =
        this._property.totalInvestmentCost -
        this._loan.downPayment +
        this._loan.bankFee +
        this._loan.landRegistryFee;
    }

    if (this._property.totalInvestmentCost > 0 && this._loan.downPayment > 0) {
      this._loan.downPaymentShare =
        this._loan.downPayment /
        (this._property.totalInvestmentCost +
          this._loan.bankFee +
          this._loan.landRegistryFee);
    }
  }

  calcLoan() {
    if (this._loan.loanAmount > 0 && this._loan.loanTerm) {
      const gracePeriod = 0;
      const interestRate = 0.007;
      const fee = 0.02;
      const capitalizationFactor = 4;

      const effectiveInterestRate =
        Math.pow(
          1 + (interestRate + fee / this._loan.loanTerm) / capitalizationFactor,
          capitalizationFactor
        ) - 1;

      const grace =
        ((effectiveInterestRate /
          Math.pow(
            1 + effectiveInterestRate,
            this._loan.loanTerm - gracePeriod
          )) *
          (this._loan.loanAmount *
            Math.pow(
              1 + effectiveInterestRate,
              this._loan.loanTerm - gracePeriod
            ))) /
        (1 -
          Math.pow(
            1 + effectiveInterestRate,
            this._loan.loanTerm - gracePeriod
          ));

      const interest = this._loan.loanAmount * effectiveInterestRate;

      this._loan.monthlyRepayment = (interest - grace) / 12;
    }
  }

  calcHousehold() {
    // if (monthlyIncoming > monthlyExpense) {
    //     monthlyBalance = monthlyIncoming - monthlyExpense;
    // }
  }

  calcFormControl() {
    switch (this.lead.property.purpose) {
      case 'purchase': {
        this.formControl.showPurchaseCost = true;
        this.formControl.showOtherPurchaseCost = true;
        this.formControl.showWithBroker = true;

        if (
          this._property.propertyType === 'plot' ||
          this._property.propertyType === 'others'
        ) {
          this.formControl.showConstructionCost = true;
        }

        if (this._property.propertyType !== 'plot') {
          this.formControl.showFurnitureCost = true;
          this.formControl.showRenovationCost = true;
          this.formControl.showUpgradingCost = true;
        }

        if (
          this._property.propertyType === 'house' ||
          this._property.propertyType === 'others'
        ) {
          this.formControl.showLandscapingCost = true;
        }

        if (
          this._property.propertyType === 'house' ||
          this._property.propertyType === 'plot' ||
          this._property.propertyType === 'others'
        ) {
          this.formControl.showEnclosureCost = true;
        }
        break;
      }
      case 'renovation': {
        this.formControl.showUpgradingCost = false;
        this.formControl.showLandscapingCost = false;
        this.formControl.showEnclosureCost = false;

        if (this._property.propertyType !== 'plot') {
          this.formControl.showRenovationCost = true;
          this.formControl.showUpgradingCost = true;
        }

        if (
          this._property.propertyType === 'house' ||
          this._property.propertyType === 'others'
        ) {
          this.formControl.showLandscapingCost = true;
        }

        if (
          this._property.propertyType === 'house' ||
          this._property.propertyType === 'plot' ||
          this._property.propertyType === 'others'
        ) {
          this.formControl.showEnclosureCost = true;
        }
        break;
      }
      case 'debt_restructuring': {
        this.formControl.showDebtRestructuringCost = true;
        this.formControl.showWithPunitiveDamages = true;
        break;
      }
      case 'others': {
        this.formControl.showPurchaseCost = true;
        this.formControl.showConstructionCost = true;
        this.formControl.showOtherPurchaseCost = true;
        this.formControl.showDebtRestructuringCost = true;
        this.formControl.showWithBroker = true;
        this.formControl.showWithPunitiveDamages = true;
        this.formControl.showFurnitureCost = true;
        this.formControl.showRenovationCost = true;
        this.formControl.showUpgradingCost = true;
        this.formControl.showLandscapingCost = true;
        this.formControl.showEnclosureCost = true;
        break;
      }
    }
  }

  resetPropertyCost() {
    if (
      this._property.purpose !== this._oldProperty.purpose ||
      this._property.propertyType !== this._oldProperty.propertyType
    ) {
      this._property = {
        ...this._property,
        ...defaultPropertyCost
      };
    }
  }
}
